var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"phone-text-field",class:{
    'mt-3': !_vm.hideTopMargin,
    className: _vm.className,
  }},[_c('v-flex',{staticClass:"max-width-80px"},[_c('v-select',{attrs:{"outlined":"","items":_vm.countryList,"hide-details":"","disabled":_vm.disabled,"loading":_vm.loading,"item-text":"name","item-value":"iso2","item-color":"blue darken-4","return-object":""},on:{"change":function($event){return _vm.updateActiveCountry(_vm.phone)}},scopedSlots:_vm._u([{key:"selection",fn:function(){return [_c('div',{staticClass:"vti__flag mr-2",class:_vm.activeCountry.iso2.toLowerCase()})]},proxy:true},{key:"item",fn:function(data){return [_c('div',{staticClass:"d-flex justify-center align-center",on:{"click":function($event){return _vm.countryClicked(data)}}},[_c('div',{staticClass:"vti__flag mr-2",class:data.item.iso2.toLowerCase()}),_c('span',[_vm._v(_vm._s(data.item.name)+" "+_vm._s(`+${data.item.dialCode}`))])])]}}]),model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}})],1),_c('v-flex',[_c('v-text-field',{class:{
        required: _vm.required && !_vm.phone ? true : false,
      },attrs:{"id":_vm.id,"outlined":"","prefix":_vm.getPrefix,"rules":_vm.rules,"hide-details":"","disabled":_vm.disabled,"loading":_vm.loading,"type":"number","hide-spin-buttons":"","placeholder":_vm.placeholder},on:{"keypress":function($event){return _vm.disableCharacter($event, _vm.phone)},"paste":function($event){return _vm.$emit('paste', $event)},"keydown":function($event){_vm.emitValue(), _vm.validatePhoneNumber()},"change":function($event){_vm.emitValue();
        _vm.validatePhoneNumber();}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),(_vm.fieldNameError)?_c('span',{staticClass:"red--text text--darken-1 font-small",domProps:{"innerHTML":_vm._s(_vm.fieldNameError)}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }